import * as React from "react";
import Content from "../contentcontainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowMaximize,
  faServer,
  faMicrochip,
  faMobile,
  faGear,
  faFingerprint,
  faDesktop,
  faCodeCommit,
  faCodeBranch,
  faWandMagicSparkles,
  faInfinity,
} from "@fortawesome/free-solid-svg-icons";
import { faDev } from "@fortawesome/free-brands-svg-icons";

const DevOps = () => {
  return (
    <Content>
      <h2 className="text-center text-4xl">DevOps</h2>
      <p className="my-10">
        DevOps is a methodology that helps organizations to speed up software development, deployment and delivery. By integrating development and operations teams, DevOps allows for faster and more efficient delivery of software updates and features.
      </p>
      <p className="my-10">
        At Semaforce, we understand the importance of DevOps in today's fast-paced business environment. Our team of experts has extensive experience in DevOps methodologies and can help you implement them in your organization. We offer a range of services, including:
      </p>
      <div className="grid gap-8 grid-cols-1 md:grid-cols-2">
        <div className="flex gap-5">
          <div className="flex-none w-8 text-4xl">
            <FontAwesomeIcon icon={faDev} />
          </div>
          <div className="flex-1">
            <h3 className="text-2xl mb-4">DevOps Consulting</h3>
            <p>
              Our consultants will work with your team to understand your unique needs and develop a tailored DevOps strategy that will help you speed up software development and delivery. We will take the time to understand your current processes, systems and pain points, and work with you to identify the areas that can be improved. Our team has a deep understanding of the various DevOps practices, tools and methodologies and will provide guidance on the best approach for your organization. Our goal is to help you implement a DevOps strategy that is tailored to your specific needs and that will enable your organization to deliver software faster, with higher quality and greater reliability. We will work closely with you throughout the entire process, from assessment to implementation, to ensure that the outcome aligns with your objectives and that you are fully satisfied with the results.
            </p>
          </div>
        </div>
        <div className="flex gap-5">
          <div className="flex-none w-8 text-4xl">
            <FontAwesomeIcon icon={faWandMagicSparkles} />
          </div>
          <div className="flex-1">
            <h3 className="text-2xl mb-4">Automation</h3>
            <p>
              We can help you automate your software development and deployment processes, reducing the time and effort required to deliver updates and features. Automation helps to eliminate repetitive manual tasks and ensure consistency across your environment. This can help to reduce the risk of human error, increase efficiency and speed up your software development and deployment process. Our team of experts can also help you in implementing continuous integration and delivery (CI/CD) practices, which automate the testing, building and deployment of software. This enables more frequent and predictable releases and faster detection and resolution of issues.
            </p>
          </div>
        </div>
        <div className="flex gap-5">
          <div className="flex-none w-8 text-4xl">
            <FontAwesomeIcon icon={faCodeBranch} />
          </div>
          <div className="flex-1">
            <h3 className="text-2xl mb-4">Infrastructure as code</h3>
            <p>
              We can help you manage your infrastructure using code, allowing for faster and more efficient deployment and scaling. This includes implementing infrastructure as code practices, which allow for version control and easy rollbacks of changes, as well as the use of automation tools to streamline the deployment process. Our team can also assist in implementing configurations management solutions such as Ansible, Chef, Helm, and Terraform. This will help in ensuring consistency and standardization across your environment, making it easier to manage and scale in the long run.
            </p>
          </div>
        </div>
        <div className="flex gap-5">
          <div className="flex-none w-8 text-4xl">
            <FontAwesomeIcon icon={faInfinity} />
          </div>
          <div className="flex-1">
            <h3 className="text-2xl mb-4">Continuous integration and delivery</h3>
            <p>
              We can help you implement continuous integration and delivery (CI/CD) practices, allowing for faster and more frequent software delivery. This includes setting up automated testing, building and deployment pipelines, and utilizing tools such as ArgoCD, Jenkins, Bitbucket, and GitLab CI/CD. Implementing CI/CD practices allows for faster detection and resolution of issues, as well as more frequent and predictable releases. Our team can also help in implementing automated monitoring and alerting, to ensure that the software is working as expected and quickly identify any issues that arise.
            </p>
          </div>
        </div>
      </div>
      <p className="my-10">
      By implementing DevOps methodologies, your organization will be able to move faster and stay ahead of the competition. With faster and more efficient software development, deployment and delivery, you will be able to respond to market changes and customer needs more quickly, helping to drive growth and innovation. Our team of experts can help you every step of the way, from strategy to implementation. We can work with you to identify and prioritize the areas where DevOps can have the biggest impact, and help you implement the necessary changes to your processes and systems. And, once the implementation is done, we will continue to support you in monitoring, maintaining and optimizing your DevOps practices to ensure that you are always ahead of the curve.
      </p>
      <div className="flex flex-shrink-0 flex-row items-center overflow-x-auto h-26 mb-10"></div>
    </Content>
  );
};

export default DevOps;
