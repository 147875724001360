import * as React from "react";

const DevOpsCTA = () => {
  return (
    <div
      className="py-32 w-full bg-zinc-900"
    >
      <div className="m-auto max-w-7xl pl-14">
        <span className="text-white font-bold text-4xl mb-6">
          Unlock Faster Development and Deployment with DevOps
        </span>
        <p className="text-white text-2xl">
          Schedule a Consultation Now
        </p>
      </div>
    </div>
  );
};

export default DevOpsCTA;
